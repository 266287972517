<template>
    <CCard>
      <CCardHeader>
        {{ $t('Users') }}
        <div class="card-header-actions">
          <CButton
            color="primary"
            v-if="isAdministrator"
            :to="{ name: 'User', params: { id: 'new' } }"
          >
            <i class="fa fa-plus" />
            {{ $t("Create") }}
          </CButton>
        </div>
      </CCardHeader>

      <CCardBody>
        <tableComponent
            :columns="['firstname', 'lastname', 'email', 'created_at', 'roles', 'actions']"
            :url="USER_ENDPOINTS.list"
            :filter-exclude-columns="['created_at', 'actions']"
            :table-override-filters="tableOverrideFilters"
            ref="table"
            v-if="roles.length"
        >
          <template #firstname="{ row }">
            {{ row.firstname || '' }}
          </template>
          <template #lastname="{ row }">
            {{ row.lastname || '' }}
          </template>
          <template #roles="{ row }">
            {{row.roles.map(r => $capitalize(r)).join(", ")}}
          </template>
          <template #actions="{ row }">
            <div class="d-flex gap-1">
              <CButton
                :to="{ name: 'User', params: { id: row.id } }"
                color="primary"
                variant="outline"
                v-c-tooltip="$t('Details about user')"
              >
                <i class="fa fa-search-plus" />
              </CButton>
              <CButton
                :to="{ name: 'User', params: { id: row.id }, query: { edit: 1 } }"
                color="primary"
                variant="outline"
                v-c-tooltip="$t('Edit user')"
                v-if="isAdministrator"
              >
                <i class="fa fa-edit" />
              </CButton>
              <CButton
                @click="() => deleteUser(row)"
                color="danger"
                variant="outline"
                v-c-tooltip="$t('Delete user')"
                v-if="isAdministrator"
              >
                <i class="fa fa-trash-o" />
              </CButton>
            </div>
          </template>
        </tableComponent>
      </CCardBody>
    </CCard>
</template>

<script>
import { USER_ENDPOINTS, UsersService } from '@/services/users';
import { ActiveUser } from '@/services/user';

export default {
  name: 'users',
  props: {},
  data() {
    return {
      USER_ENDPOINTS,
      isAdministrator: ActiveUser.isAdministrator(),
      roles: [],
    };
  },
  mounted() {
    this.fetchRoles();
  },
  computed: {
    tableOverrideFilters() {
      return {
        roles: {
          options: this.roles.map((option) => ({
            label: this.$capitalize(option),
            value: option,
          })),
        },
      };
    },
  },
  methods: {
    async fetchRoles() {
      this.roles = await UsersService.getRoles();
    },
    async deleteUser(user) {
      if (!await confirm(this.$t('user_delete', { email: user.email }))) {
        return;
      }
      try {
        await UsersService.deleteUser(user.id);
      } catch (e) {
      }
      this.$refs.table.$refs.table.getData();
    },
  },
};
</script>
